<template>
  <div class="theme-creator" @mousedown="outsideClick">
    <v-overlay v-if="themeLoading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <canvas-create style="padding-bottom: 60px;" :key="componentKey" :width="width" :height="height" @fcanvas="editFcanvas"/>
    <v-navigation-drawer
        right
        v-model="drawerRight"
        width="350px"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        class="pa-4"
    >
    <drawer-right-theme v-if="!loading && !fontsLoading"
        @addBackPattern="addBackPattern"
        @add="addObj"
        :objSelected="objSelected"
        :fontsLoading="fontsLoading"
        :refreshSetting="refreshSetting"
        @remove="removeObj"
        @renderAll="renderAll"
        :fonts="fonts"
        :fcanvas="fcanvas"
        @showGuidelines="showGuidelinesChanged"
        :showGuidelines="showGuidelines"
        @selectTemplate="reinitializeTemplate"
    >
    </drawer-right-theme>
    </v-navigation-drawer>
    <v-dialog v-model="importSvgModal" max-width="500">
      <v-card>
        <v-card-title class="headline" v-t="'ThemeCreator.ImportSVG'"></v-card-title>
        <v-file-input v-model="inputFileSvg" accept="image/svg+xml" :label="$t('ThemeCreator.SelectedFile')"
                      style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importSvgModal = false" v-t="'General.Cancel'"></v-btn>
          <v-btn color="primary' darken-1" text @click="addSvg" v-t="'General.Confirm'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="importImageModal" max-width="500">
      <v-card>
        <v-card-title class="headline" v-t="'ThemeCreator.ImportImage'"></v-card-title>
        <v-file-input v-model="inputFileImage" accept="image/*" :label="$t('ThemeCreator.SelectedFile')"
                      style="width: 95%"></v-file-input>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="importImageModal = false" v-t="'General.Cancel'"></v-btn>
          <v-btn color="primary' darken-1" text @click="addImage" v-t="'General.Confirm'"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <svg id="SVG-ElementID"></svg>
    <v-footer fixed class="font-weight-medium">
        <v-btn @click="saveCanvas" v-t="'ThemeCreator.SaveTheme'" class="save-theme-button">
        </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import DrawerRightTheme from '../components/drawerRight/DrawerRightTheme'
import CanvasCreate from '../components/drawerRight/CanvasCreate'
import WebFont from 'webfontloader'

export default {
  name: 'ThemeCreator',
  components: {
    DrawerRightTheme, CanvasCreate
  },
  data () {
    return {
      width: window.innerWidth / 2.5,
      height: window.innerWidth / 2.5,
      fcanvas: null,
      objSelected: null,
      drawerRight: true,
      currentTheme: {},
      importSvgModal: false,
      inputFileSvg: null,
      idDeco: '',
      importImageModal: false,
      refreshSetting: 0,
      inputFileImage: null,
      themeLoading: true,
      fontsLoading: true,
      componentKey: 0,
      themeFonts: [],
      fonts: [
        { id: 0, name: 'Arial' },
        { id: 1, name: 'Arial Black' },
        { id: 2, name: 'Bookman Old Style' },
        { id: 3, name: 'Comic Sans MS' },
        { id: 4, name: 'Courier' },
        { id: 5, name: 'Courier New' },
        { id: 6, name: 'Garamond' },
        { id: 7, name: 'Georgia' },
        { id: 8, name: 'Impact' },
        { id: 9, name: 'Lucida Console' },
        { id: 10, name: 'Lucida Sans Unicode' },
        { id: 11, name: 'MS Sans Serif' },
        { id: 12, name: 'MS Serif' },
        { id: 13, name: 'Palatino Linotype' },
        { id: 14, name: 'Symbol' },
        { id: 15, name: 'Tahoma' },
        { id: 16, name: 'Times New Roman' },
        { id: 17, name: 'Trebuchet MS' },
        { id: 18, name: 'Verdana' }],
        showGuidelines: true,
        templates: [],
        newTemplate: true,
        loading: true
    }
  },
  created () {
    this.initializeTemplate()
  },
  mounted () {
    this.$store.dispatch('clearSelectedFonts')
  },
  methods: {
    async initializeTemplate () {
      try {
        await this.$store.dispatch('getAllTemplates')
        await this.$store.dispatch('getTheme', this.$route.params.id)
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        const template = this.findTemplate()
        this.$store.state.selectedTemplate = template
        this.setCanvasSize(template)
        if (this.currentTheme.themeJson) {
          this.newTemplate = false
        }
        this.loading = false
      } catch (err) {
        console.error(err)
      }
    },
    reinitializeTemplate (template) {
      this.currentTheme.templateId = template.id
      this.setCanvasSize(template)
      this.newTemplate = true
    },
    findTemplate () {
      return this.$store.state.templates.find(el => {
        if (this.currentTheme.templateId) {
          return el.id === this.currentTheme.templateId
        }
        if (!this.currentTheme.templateId && el.name === '1:1') {
          this.currentTheme.templateId = el.id
        }
        return el.name === '1:1'
      })
    },
    setCanvasSize (template) {
      this.height = this.width * (template.height / template.width)
      this.componentKey += 1
      this.newTemplate = true
    },
    editFcanvas (fcanvas) {
      this.initializeCanvasEvents(fcanvas)
      if (this.newTemplate || !this.currentTheme.themeJson) {
         this.themeLoading = false
         this.getAllGoogleFonts()
      } else {
        this.loadAllFonts()
      }
      this.createLines()
    },
    initializeCanvasEvents (fcanvas) {
      this.fcanvas = fcanvas
      this.fcanvas.on({
        'selection:created': (e) => {
          if (!this.hover) {
            this.select(e)
          }
        },
        'selection:cleared': () => {
          this.objSelected = null
        },
        'selection:updated': (e) => {
          if (!this.hover) {
            this.objSelected = null
            this.select(e)
          }
        },
        'object:modified': () => {
          this.refreshSetting += 1
        }
      })
    },
    loadAllFonts () {
      const parsedJson = JSON.parse(this.currentTheme.themeJson)
      if (parsedJson && parsedJson.fonts.length > 0) {
        this.loadThemeFonts(parsedJson.fonts)
      } else {
        this.loadThemeJSON()
      }
      this.getAllGoogleFonts()
    },
    loadThemeFonts (fonts) {
      fonts.forEach((font) => {
        this.themeFonts.push({ id: this.themeFonts.length, name: font.split('family=')[1], googleFont: true })
      })
      WebFont.load({
        google: {
          families: this.themeFonts.map(font => font.name)
        },
        active: () => {
          this.loadThemeJSON()
        }
      })
    },
    getAllGoogleFonts () {
      this.$axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDJC2o9SopWiTzGrDbYaPZagP9gmxwEHgw').then(async (res) => {
        res.data.items.forEach((item) => {
          this.fonts.push({ id: this.fonts.length, name: item.family, googleFont: true })
        })
        this.fonts.sort((a, b) => b.name < a.name ? 1 : -1)
        if (!this.$store.state.areFontsLoaded) {
          this.loadGoogleFonts()
        } else {
          this.fontsLoading = false
        }
      })
    },
    loadGoogleFonts () {
      const fontsCopy = [...this.fonts]
      while (fontsCopy.length) {
        const current = fontsCopy.splice(0, 100)
        WebFont.load({
          google: {
            families: current.filter(font => font.googleFont).map(font => font.name)
          },
          active: () => {
            this.fontsLoading = false
            this.$store.state.areFontsLoaded = true
          }
        })
      }
    },
    loadThemeJSON () {
      this.fcanvas.loadFromJSON(this.currentTheme.themeJson, () => {
        const boundingRect = this.fcanvas.getObjects().find(obj => obj.name === 'boundingRect')
        this.fcanvas.remove(boundingRect)
        fabric.util.clearFabricFontCache()
        this.rescaleObjects()
        this.handleClipPath()

        if (this.currentTheme.idDeco) {
          this.$store.dispatch('getAllDecos').then(() => {
            const motifs = this.$store.getters['GET_DECOS']()
            const motifToLoad = motifs.find(motif => motif.id === this.currentTheme.idDeco)
            if (motifToLoad) {
              this.addBackPattern(motifToLoad)
            } else {
              this.themeLoading = false
            }
          })
        } else {
          this.themeLoading = false
        }
        this.createLines()
      })
    },
    rescaleObjects () {
      const scale = this.fcanvas.getHeight() / 256
      this.fcanvas.setDimensions({
        width: this.width,
        height: this.height
      })
      this.fcanvas.calcOffset()
      const objects = this.fcanvas.getObjects()
      objects.forEach(obj => {
        const { scaleX, scaleY, left, top } = obj
        obj.scaleX = scaleX * scale
        obj.scaleY = scaleY * scale
        obj.left = left * scale
        obj.top = top * scale
        obj.setCoords()
      })
      this.fcanvas.renderAll()
    },
    handleClipPath () {
      this.fcanvas.getObjects().forEach(objMask => {
        if (objMask.isMask && objMask.customClipPathId) {
          this.fcanvas.getObjects().forEach(objToClip => {
            if (!objToClip.isMask && objToClip.customClipPathId === objMask.customClipPathId) {
              objToClip.clipPath = objMask
              this.fcanvas.renderAll()
            }
          })
        }
      })
    },
    createLines () {
      const centerHorizontalLine = new fabric.Line([0, this.fcanvas.getHeight() / 2, this.fcanvas.getWidth(), this.fcanvas.getHeight() / 2], {
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false,
        visible: this.showGuidelines,
        name: 'line'
      })
      const centerVerticalLine = new fabric.Line([this.fcanvas.getWidth() / 2, 0, this.fcanvas.getWidth() / 2, this.fcanvas.getHeight()], {
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false,
        visible: this.showGuidelines,
        name: 'line'
      })
      this.fcanvas.add(centerVerticalLine, centerHorizontalLine)
    },
    showGuidelinesChanged (isShowGuidelines) {
      this.showGuidelines = isShowGuidelines
      this.fcanvas.getObjects().forEach((obj) => {
        if (obj.name === 'line') {
          obj.visible = this.showGuidelines
        }
      })
      this.fcanvas.renderAll()
    },
    addBackPattern (motif) {
      this.currentTheme.idDeco = motif.id
      fabric.loadSVGFromURL(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + motif.svgPath, (svgpattern) => {
        var ptnGroup = new fabric.Group(svgpattern, {
          top: 0,
          left: 0
        })
        ptnGroup.scaleX = this.fcanvas.width / 4 / ptnGroup.width / window.devicePixelRatio
        ptnGroup.scaleY = this.fcanvas.width / 4 / ptnGroup.width / window.devicePixelRatio
        var colors = []
        ptnGroup.forEachObject((o) => {
          var i = colors.indexOf(o.fill)
          if (i === -1) {
            colors.push(o.fill)
            i = colors.length - 1
          }
          o.groupe = i
        })
        var patternSourceCanvas = new fabric.Canvas()
        patternSourceCanvas.add(ptnGroup)
        patternSourceCanvas.renderAll()
        patternSourceCanvas.setDimensions({
          width: ptnGroup.getScaledWidth(),
          height: ptnGroup.getScaledHeight()
        })
        patternSourceCanvas.renderAll()
        var pattern = new fabric.Pattern({
          source: patternSourceCanvas.getElement(),
          repeat: 'repeat'
        })

        var shape = new fabric.Rect({
          width: this.fcanvas.getWidth(),
          height: this.fcanvas.getHeight(),
          left: -1,
          top: 0,
          name: 'backPattern',
          hasBorders: false,
          selectable: false,
          evented: false,
          hasControls: false,
          fill: pattern,
          objectCaching: false
        })
        shape.svg = ptnGroup
        this.fcanvas.add(shape)
        shape.sendToBack()
        this.fcanvas.renderAll()
        this.themeLoading = false
      })
    },
    outsideClick (event) {
      if (event.target === this.$el) {
        this.fcanvas.discardActiveObject()
        this.fcanvas.renderAll()
      }
    },
    select (o) {
      this.objSelected = o.target ? o.target : o
    },
    removeObj (obj) {
      if (obj.isMask) {
        this.fcanvas.getObjects().forEach((object) => {
          if (!object.isMask && object.customClipPathId === obj.customClipPathId) {
            this.fcanvas.remove(object)
          }
        })
      }
      this.fcanvas.remove(obj)
    },
    addObj (obj) {
      switch (obj) {
        case 'line':
          this.addLine()
          break
        case 'square':
          this.addSquare()
          break
        case 'text':
          this.addText()
          break
        case 'circle':
          this.addCircle()
          break
        case 'svg':
          this.importSvgModal = true
          break
        case 'image':
          this.importImageModal = true
          break
      }
    },
    addSquare () {
      var square = new fabric.Rect({
        left: 100,
        top: 100,
        width: 100,
        height: 100,
        absolutePositioned: true,
        originX: 'center',
        originY: 'center',
        fill: '#FFFFFF',
        stroke: '#000000'
      })
      this.fcanvas.add(square)
    },
    addLine () {
      var line = new fabric.Line([200, 300, 400, 300], {
        left: 100,
        top: 100,
        stroke: 'rgb(0,0,0)',
        fill: 'rgb(0,0,0)'
      })
      this.fcanvas.add(line)
    },
    addText () {
      var text = new fabric.IText('Texte', {
        left: 100,
        top: 100,
        strokeWidth: 0,
        originX: 'center',
        originY: 'center'
      })
      this.fcanvas.add(text)
    },
    addCircle () {
      var circle = new fabric.Circle({
        radius: 50,
        originX: 'center',
        originY: 'center',
        left: 100,
        top: 100,
        absolutePositioned: true,
        fill: '#FFFFFF',
        stroke: '#000000'
      })
      this.fcanvas.add(circle)
    },
    renderAll () {
      this.fcanvas.renderAll()
    },
    addSvg () {
      this.importSvgModal = false
      const reader = new FileReader()
      reader.onload = (event) => {
        const data = event.target.result
        fabric.loadSVGFromURL(data, (objects, options) => {
          var imgInstance = fabric.util.groupSVGElements(objects, options)
          imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.set({
            originX: 'center',
            originY: 'center',
            left: 126,
            top: 126,
            absolutePositioned: true // importance maximale
          })
          if (imgInstance.forEachObject) {
            imgInstance.forEachObject((o) => {
              o.evented = true
              o.onSelect = () => {
              }
            })
          }
          this.fcanvas.add(imgInstance)
        })
      }
      reader.readAsDataURL(this.inputFileSvg)
    },
    addImage () {
      this.importImageModal = false
      const reader = new FileReader()
      reader.onload = () => {
        var newImage = new Image()
        newImage.src = reader.result
        newImage.addEventListener('load', () => {
          var imgInstance = new fabric.Image(newImage, {
            originX: 'center',
            originY: 'center',
            left: 126,
            top: 126
          })

          imgInstance.scaleX = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))
          imgInstance.scaleY = (250 / (imgInstance.width < imgInstance.height ? imgInstance.width : imgInstance.height))

          this.fcanvas.add(imgInstance)
        })
      }
      reader.readAsDataURL(this.inputFileImage)
    },
    fixSVGText (str) {
      var svg = new DOMParser().parseFromString(str, 'image/svg+xml').documentElement

      var tspans = svg.querySelectorAll('tspan')
      for (var i = 0; i < tspans.length; i++) {
        var ts = tspans[i]
        var parent = ts.parentNode
        var gParent = parent.parentNode
        var j = 0

        var replace = document.createElementNS('http://www.w3.org/2000/svg', 'text')

        var tsAttr = ts.attributes
        for (j = 0; j < tsAttr.length; j++) {
          replace.setAttribute(tsAttr[j].name, tsAttr[j].value)
        }

        var childNodes = ts.childNodes
        for (j = 0; j < childNodes.length; j++) {
          replace.appendChild(ts.childNodes[j])
        }

        var tAttr = parent.attributes
        for (j = 0; j < tAttr.length; j++) {
          replace.setAttribute(tAttr[j].name, tAttr[j].value)
        }
        gParent.appendChild(replace)

        if (ts === parent.lastElementChild) {
          gParent.removeChild(parent)
        }
      }
      return new XMLSerializer().serializeToString(svg)
    },
    saveCanvas () {
      this.fcanvas.getObjects().forEach(obj => {
        if (obj.name === 'line') {
          this.fcanvas.remove(obj)
        }
      })
      this.themeLoading = true

      if (!this.fcanvas.getObjects().find(obj => obj.name === 'boundingRect')) {
        const boundingRect = new fabric.Rect({
          height: this.fcanvas.height,
          width: this.fcanvas.width,
          originX: 'center',
          originY: 'center',
          left: this.fcanvas.width / 2,
          top: this.fcanvas.height / 2,
          fill: '',
          name: 'boundingRect',
          selectable: false,
          evented: false
        })
        this.fcanvas.add(boundingRect)
        boundingRect.sendToBack()
      }

      let objects = this.fcanvas.getObjects()

      var scale = 256 / this.fcanvas.getHeight()
      var width = scale * this.fcanvas.getWidth()
      this.fcanvas.setDimensions({
        width: width,
        height: 256
      })
      this.fcanvas.calcOffset()

      for (var i in objects) {
        var scaleX = objects[i].scaleX
        var scaleY = objects[i].scaleY
        var left = objects[i].left
        var top = objects[i].top

        objects[i].scaleX = scaleX * scale
        objects[i].scaleY = scaleY * scale
        objects[i].left = left * scale
        objects[i].top = top * scale
        objects[i].setCoords()

        this.fcanvas.renderAll()
      }

      objects = this.fcanvas.getObjects()
      objects.forEach(obj => {
        if (obj.fontFamily) {
          this.$store.dispatch('addSelectedFont', obj.fontFamily)
        }
      })

      var dataPreview = new FormData()
      var svg = this.fixSVGText(this.fcanvas.toSVG())
      var selectedFonts = this.$store.getters['GET_SELECTED_FONTS']()
      var backPattern = this.fcanvas.getObjects().find(o => o.name === 'backPattern')
      var base64 = this.fcanvas.toDataURL('image/png')

      if (backPattern) {
        this.fcanvas.remove(backPattern)
      } else {
        this.currentTheme.idDeco = ''
      }

      var json = this.fcanvas.toJSON([
        'absolutePositioned',
        'customClipPathId',
        'isMask',
        'modifiable',
        'name',
        'originX'
      ])

      json['fonts'] = []
      if (selectedFonts.length > 0) {
        var svgTest = svg.split('<defs>')
        selectedFonts
          .filter(selectedFont =>
            this.fonts.find(font => font.name === selectedFont && font.googleFont)
          )
          .forEach(font => {
            var url = 'https://fonts.googleapis.com/css?family=' + font.replaceAll(' ', '+')
            json['fonts'].push(url)
            svgTest[1] = '@import url(' + url + ');' + svgTest[1]
          })

        svgTest[1] = '<defs><style>' + svgTest[1]
        svgTest = svgTest.join('')
        var svgTestStyle = svgTest.split('</defs>')
        svgTestStyle[1] = '</style></defs>' + svgTestStyle[1]
        svg = svgTestStyle.join('')
      }

      var blobPreview = new Blob([
        this.dataURLtoFile(
          base64,
          this.currentTheme.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png'
        )
      ])

      dataPreview.append(
        'fileName',
        this.currentTheme.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png'
      )
      dataPreview.append('id', this.currentTheme.id)
      dataPreview.append('file', blobPreview)

      this.currentTheme.themeJson = JSON.stringify(json)

      this.$store.dispatch('editTheme', this.currentTheme).then((res) => {
        this.$store.dispatch('uploadThemePreview', dataPreview).then(() => {
        this.$router.push({ name: 'Themes' })
        })
      })
    },
    storeAllObjects () {
      this.$store.dispatch('storeAllObjects', this.fcanvas.getObjects())
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style scoped>
.theme-creator {
  background-color: #555;
  height: 83vh;
}
.file-input {
  width: 95%;
}
.v-footer {
  height: 6vh;
}
.save-theme-button {
  margin: auto;
}
</style>

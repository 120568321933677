<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'ThemesForm.ThemeEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Themes' })" v-t="'General.Return'"></v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.name"
                  :label="$t('ThemesForm.ThemeName')"
                  required
                  outlined
              ></v-text-field>
            </v-col>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.sku"
                  :label="$t('ThemesForm.ThemeReference')"
                  required
                  maxlength="6"
                  outlined
                  @input="capsSku"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentTheme.author"
                  :label="$t('ThemesForm.ThemeAuthor')"
                  required
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  id="sectionsForm"
                  v-model="currentTheme.sectionList"
                  :items="sections"
                  item-text="name"
                  item-value="id"
                  :label="$t('ThemesForm.Sections')"
                  multiple
                  outlined
                  clearable
                  required></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="currentTheme.templateId"
                :items="templates"
                item-text="name"
                item-value="id"
                :label="$t('ThemesForm.SelectTemplate')"
                outlined
                @change="selectTemplate"
                required
                :disabled="isTemplateMustBeDisabled"
              ></v-select>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-title v-t="'General.ListTranslations'"></v-card-title>
            <v-card-text>
              <v-row class="align-center" v-for="lang in currentTheme.labelList" :key="lang.langValue">
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-chip>
                    {{
                      languageList.find((l) => {
                        return l.value === lang.langValue
                      }).country
                    }}
                  </v-chip>
                </v-col>
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-text-field
                      v-model="lang.label"
                      required
                      outlined
                      clearable
                      :label="$t('ThemesForm.ThemeNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card outlined class="mt-10">
            <v-card-title v-t="'ThemesForm.ListSearchTags'"></v-card-title>
            <v-card-text>
              <v-row style="align-items: baseline">
                <v-col>
                  <v-select
                      id="tagsForm"
                      v-model="currentTheme.tagList"
                      :items="tags"
                      item-text="name"
                      item-value="id"
                      :label="$t('ThemesForm.Keywords')"
                      multiple
                      required
                      outlined
                  ></v-select>
                </v-col>
                <v-col>
                  <div class='my-2 text-left pa-2'>
                    <v-btn @click='addTagForm'>{{ newTag ? $t('General.Cancel') : $t('ThemesForm.CreateTag') }}</v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="newTag">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="tag.name"
                          :label="$t('ThemesForm.CreateTag')"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row v-for="lang in tag.labelList" :key="lang.langValue">
                    <v-col>
                      <v-chip>
                        {{
                          languageList.find((l) => {
                            return l.value === lang.langValue
                          }).country
                        }}
                      </v-chip>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="lang.label"
                          required
                          outlined
                          :label="$t('ThemesForm.ThemeNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class='my-2 text-right pa-2'>
                        <v-btn @click='addTag' v-t="'Tags.AddTag'"></v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentTheme.colorsEditable"
            :label="$t('ThemesForm.CustomizableColors')"
        ></v-switch><v-switch
            style="margin-right: 30px"
            v-model="currentTheme.published"
            :label="$t('General.Publish')"
        ></v-switch>
        <v-btn color='primary' @click='addOrModifyTheme(false)' v-t="'General.Finish'"></v-btn>
        <v-btn style="margin-left: 5px" color='primary' @click='addOrModifyTheme(true)' v-t="'General.Continue'"></v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ThemesForm',
  data () {
    return {
      valid: false,
      currentTheme: {
        id: '',
        sku: '',
        name: '',
        labelList: [],
        published: false,
        colorsEditable: true,
        tagList: [],
        sectionList: [],
        author: '',
        templateId: ''
      },
      tags: [],
      newTag: false,
      tag: {
        name: '',
        labelList: []
      },
      themes: [],
      sections: [],
      languageList: [],
      newTemplate: false,
      templates: []
      }
  },
  created () {
  },
  mounted () {
    this.getTemplates()
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentTheme()
    } else {
      this.getSites()
    }
  },
  computed: {
    isTemplateMustBeDisabled () {
      return this.currentTheme.id !== ''
    }
  },
  methods: {
    selectTemplate () {
      this.$emit('selectTemplate', this.currentTheme.templateId)
    },
    capsSku () {
      this.currentTheme.sku = this.currentTheme.sku.toUpperCase()
    },
    getSections () {
      this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
      })
    },
    getSites () {
      this.getSections()
      this.$store.dispatch('getAllSites').then(() => {
        this.sites = this.$store.getters['GET_SITES']()
        this.languageList = []
        this.sites.forEach((site) => {
          site.languageList.forEach((langToAdd) => {
            if (!this.languageList.find((lang) => {
              return lang.value === langToAdd.value
            })) {
              this.languageList.push(langToAdd)
            }
          })
        })
        this.languageList.forEach((lang) => {
          if (!this.currentTheme.labelList.find((langLabel) => {
            return langLabel.langValue === lang.value
          })) {
            this.currentTheme.labelList.push({ langValue: lang.value, label: '' })
          }
        })
        this.getTags()
      })
    },
    addTagForm () {
      if (this.newTag) {
        this.newTag = false
      } else {
        this.tag.name = ''
        this.tag.labelList = []
        this.newTag = true
        this.languageList.forEach((lang) => {
          this.tag.labelList.push({ langValue: lang.value, label: '' })
        })
      }
    },
    addTag () {
      this.newTag = false
      this.$store.dispatch('addTag', this.tag).then(() => {
        var addedTag = this.$store.getters['GET_CURRENT_TAG']()
        this.tags.push(addedTag)
        this.currentTheme.tagList.push(addedTag.id)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    getTags () {
      this.$store.dispatch('getAllTags').then(() => {
        this.tags = this.$store.getters['GET_TAGS']()
      })
    },
    getCurrentTheme () {
      this.$store.dispatch('getTheme', this.$route.params.id).then(() => {
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        this.$store.commit('SET_LAST_SELECTED_THEME', this.currentTheme.id ? this.currentTheme.id : null)
        if (this.currentTheme === null) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('ThemesForm.ThemeNotFound')
          })
          this.$router.push({ name: 'Themes' })
        }
        this.getSites()
      })
    },
    addOrModifyTheme (continuer) {
      this.$refs.form.validate()
      this.isSkuAvailable().then((res) => {
        if (res) {
          if (this.valid) {
            if (this.currentTheme.id) {
              this.modifyTheme(continuer)
            } else {
              this.addTheme(continuer)
            }
          }
        } else {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('ThemesForm.SKUAlreadyUse')
          })
        }
      })
    },
    addTheme (continuer) {
      this.$store.dispatch('addTheme', this.currentTheme).then(() => {
        if (this.currentTheme.templateId != null) {
          this.$store.commit('SET_SELECTED_TEMPLATE', this.currentTheme.templateId)
        }
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        continuer ? this.$router.push('/ThemeCreator/' + this.currentTheme.id) : this.$router.push('/Themes')
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    modifyTheme (continuer) {
      this.$store.dispatch('editTheme', this.currentTheme).then(() => {
        this.currentTheme = this.$store.getters['GET_CURRENT_THEME']()
        continuer ? this.$router.push('/ThemeCreator/edit/' + this.currentTheme.id) : this.$router.push('/Themes')
      }).catch((err) => {
        console.log(err)
      })
    },
    async isSkuAvailable () {
      await this.$store.dispatch('getAllThemes')
      var themes = this.$store.getters['GET_THEMES']()
      var result = true
      themes.forEach((theme) => {
        if (theme.id !== this.currentTheme.id && theme.sku.toLowerCase() === this.currentTheme.sku.toLowerCase()) {
          result = false
        }
      })
      return result
    },
    async getTemplates () {
      this.$store.dispatch('getAllTemplates').then(() => {
        this.templates = this.$store.state.templates
      }).catch(err => {
        console.log(err)
        this.$emit('notify', {
            color: 'red',
            text: this.$t('ThemesForm.TemplatesNotFound')
          })
      })
    }
  }
}
</script>

<style scoped>

</style>

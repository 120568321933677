<template>
  <div class="pa-5">
    <v-overlay v-if="uploading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'SitesForm.WebsiteEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' v-t="'General.Return'" @click="$router.push({ name: 'Sites' })"></v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form" class="pa-4">
        <v-overlay v-if="loading">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-tabs background-color="grey lighten-4" v-model="tab" grow>
          <v-tab v-t="'SitesForm.General'" />
          <v-tab v-t="'SitesForm.ColorsManagement'" />
          <v-tab v-t="'SitesForm.ProductsManagement'" />
          <v-tab v-t="'SitesForm.PatternsManagement'" />
          <v-tab v-t="'SitesForm.ThemesManagement'" />
          <v-tab-item>
            <div class="mt-5"></div>
            <v-row>
              <v-col
              >
                <v-text-field
                  v-model="currentSite.name"
                  :label="$t('SitesForm.SiteName')"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
              >
                <v-text-field
                  v-model="currentSite.url"
                  :label="$t('SitesForm.URL')"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
              >
                <v-text-field
                  v-model="currentSite.urlCGV"
                  :label="$t('SitesForm.URLGTC')"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              >
                <v-text-field
                  v-model="currentSite.shopUrl"
                  :label="$t('SitesForm.URLShop')"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
              >
                <v-autocomplete
                  :label="$t('SitesForm.Languages')"
                  :items="languagesComputed"
                  v-model="currentSite.languageList"
                  multiple
                  chips
                  deletable-chips
                  :search-input.sync="searchLanguage"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="switchInCol"
              >
                <v-file-input @click:append="logoComputed ? $store.dispatch('downloadFile', currentSite.logo) : null" :append-icon="logoComputed ? 'mdi-download-circle' : null" ref="fileInput" color="primary" class="ma-5" v-model="inputFileLogo"
                              @change='showPrefixLogo = false' outlined
                              accept="image/*" :clearable="false" :label="$t('SitesForm.AddDesktopLogo')" :prefix="logoComputed"/>
                <v-file-input @click:append="logoMobileComputed ? $store.dispatch('downloadFile', currentSite.logoMobile) : null" :append-icon="logoMobileComputed ? 'mdi-download-circle' : null" ref="fileInput" color="primary" class="ma-5" v-model="inputFileLogoMobile"
                              @change='showPrefixLogoMobile = false' outlined
                              accept="image/*" :clearable="false" :label="$t('SitesForm.AddMobileLogo')" :prefix="logoMobileComputed"/>
                <v-file-input @click:append="socleComputed ? $store.dispatch('downloadFile', currentSite.baseTexture) : null" :append-icon="socleComputed ? 'mdi-download-circle' : null" ref="fileInput" color="primary" class="ma-5" v-model="inputFileSocle"
                              @change='showPrefixSocle = false' outlined
                              accept="image/*" :clearable="false" :label="$t('SitesForm.AddABase')" :prefix="socleComputed"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                v-model="currentSite.exportPSD"
                :label="$t('SitesForm.SiteDedicatedToFileExports')"
                ></v-switch>
              </v-col>
              <v-col>
                <v-switch
                  v-model="currentSite.shouldShowUploadLogoModal"
                  :label="$t('SitesForm.DisplayLogoModalProof')"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="switchInCol"
              >
                <v-btn outlined class="ma-5 py-3" style="width: 100%; height: 50px" v-t="'SitesForm.EditTranslationJSON'" @click="changeTrad = true"></v-btn>
              </v-col>
              <v-col class="switchInCol">
                <v-btn outlined class="ma-5 py-3" style="width: 100%; height: 50px" v-t="'SitesForm.EditConfiguration'" @click="changeConfig = true"></v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="switchInCol" v-for="feature in currentSite.features" :key="feature.name + 'row1'"
              >
                <v-switch
                  :label="$t('General.' + feature.name)"
                  v-model="feature.value"
                ></v-switch>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-row>
              <v-col
              >
                <v-text-field
                    v-model="currentSite.sceneBackgroundColor"
                    :label="$t('SitesForm.SceneColor')"
                    required
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="currentSite.primaryColor"
                    :label="$t('SitesForm.MainColor')"
                    required
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="currentSite.secondaryColor"
                    :label="$t('SitesForm.SecondaryColor')"
                    required
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-treeview style="text-align: left" transition rounded hoverable dense selectable :items="productsCategorized" v-model="selectedProductsAndCategories"></v-treeview>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-treeview style="text-align: left" transition rounded hoverable selectable :items="decosCategorized" v-model="selectedDecosAndSections"></v-treeview>
          </v-tab-item>
          <v-tab-item>
            <div class="mt-5"></div>
            <v-treeview style="text-align: left" transition rounded hoverable dense selectable :items="themesCategorized" v-model="selectedThemesAndSections"></v-treeview>
          </v-tab-item>
        </v-tabs>
      </v-form>
      <div class='text-right pa-3'>
        <v-btn :disabled="!valid" color='primary' @click='addOrModifySite'>{{
            $route.params.id.indexOf('new') === -1 ?
            $t('General.Edit') : $t('General.Add')
          }}
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="changeTrad" width="70%">
      <v-card>
        <v-card-title v-t="'SitesForm.EditTranslation'" class="text-h5 primary lighten-2">
        </v-card-title>

        <v-card-text style="overflow-y: scroll; max-height: 70vh;">
          <v-textarea auto-grow v-model="translation"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class='text-right pa-3'>
            <v-btn color="primary" v-t="'General.Confirm'" @click="changeTranslation()">
              Valider
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeConfig" width="70%">
      <v-card>
        <v-card-title v-t="'SitesForm.EditConfiguration'" class="text-h5 primary lighten-2">
        </v-card-title>

        <v-card-text style="overflow-y: scroll; max-height: 70vh;">
          <v-textarea auto-grow v-model="configuration"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class='text-right pa-3'>
            <v-btn color="primary" v-t="'General.Confirm'" @click="changeConfiguration()">
              Valider
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SitesForm',
  data () {
    return {
      loading: true,
      wantedProducts: [],
      wantedSections: [],
      wantedDecos: [],
      wantedThemes: [],
      tab: 0,
      currentSite: {
        id: '',
        baseTexture: '',
        logo: '',
        logoMobile: '',
        translationUrl: '',
        configurationUrl: '',
        decoList: [],
        urlCGV: '',
        shopUrl: '',
        languageList: [],
        name: '',
        productExceptionList: [],
        sectionExceptionList: [],
        decoExceptionList: [],
        themeExceptionList: [],
        sceneBackgroundColor: '',
        primaryColor: '',
        secondaryColor: '',
        exportPSD: false,
        shouldShowUploadLogoModal: false,
        url: '',
        features: [
          { name: 'Products', value: true },
          { name: 'Themes', value: true },
          { name: 'Patterns', value: true },
          { name: 'Texts', value: true },
          { name: 'Image', value: true },
          { name: 'Measures', value: true },
          { name: 'Layers', value: true },
          { name: 'Import', value: true },
          { name: 'Unsplash', value: true }
        ]
      },
      inputFileSocle: {},
      showPrefixSocle: true,
      inputFileLogo: {},
      showPrefixLogo: true,
      inputFileLogoMobile: {},
      showPrefixLogoMobile: true,
      inputFileTranslation: {},
      showPrefixTranslation: true,
      languages: [
        { value: 'AB', country: 'Abkhazian' },
        { value: 'AA', country: 'Afar' },
        { value: 'AF', country: 'Afrikanns' },
        { value: 'SQ', country: 'Albanian' },
        { value: 'AM', country: 'Amharic' },
        { value: 'AR', country: 'Arabic' },
        { value: 'HY', country: 'Armenian' },
        { value: 'AS', country: 'Assamese' },
        { value: 'AY', country: 'Aymara' },
        { value: 'AZ', country: 'Azerbaijani' },
        { value: 'BA', country: 'Bashkir' },
        { value: 'EU', country: 'Basque' },
        { value: 'BN', country: 'Bengali, Bangla' },
        { value: 'DZ', country: 'Bhutani' },
        { value: 'BH', country: 'Bihari' },
        { value: 'BI', country: 'Bislama' },
        { value: 'BR', country: 'Breton' },
        { value: 'BG', country: 'Bulgarian' },
        { value: 'MY', country: 'Burmese' },
        { value: 'BE', country: 'Byelorussian' },
        { value: 'KM', country: 'Cambodian' },
        { value: 'CA', country: 'Catalan' },
        { value: 'ZH', country: 'Chinese (Mandarin)' },
        { value: 'CO', country: 'Corsican' },
        { value: 'HR', country: 'Croation' },
        { value: 'CS', country: 'Czech' },
        { value: 'DA', country: 'Danish' },
        { value: 'NL', country: 'Dutch' },
        { value: 'EN', country: 'English' },
        { value: 'EO', country: 'Esperanto' },
        { value: 'ET', country: 'Estonian' },
        { value: 'FO', country: 'Faeroese' },
        { value: 'FJ', country: 'Fiji' },
        { value: 'FI', country: 'Finnish' },
        { value: 'FR', country: 'French' },
        { value: 'FY', country: 'Frisian' },
        { value: 'GD', country: 'Gaelic (Scots Gaelic)' },
        { value: 'GL', country: 'Galician' },
        { value: 'KA', country: 'Georgian' },
        { value: 'DE', country: 'German' },
        { value: 'EL', country: 'Greek' },
        { value: 'KL', country: 'Greenlandic' },
        { value: 'GN', country: 'Guarani' },
        { value: 'GU', country: 'Gujarati' },
        { value: 'HA', country: 'Hausa' },
        { value: 'IW', country: 'Hebrew' },
        { value: 'HI', country: 'Hindi' },
        { value: 'HU', country: 'Hungarian' },
        { value: 'IS', country: 'Icelandic' },
        { value: 'IN', country: 'Indonesian' },
        { value: 'IA', country: 'Interlingua' },
        { value: 'IE', country: 'Interlingue' },
        { value: 'IK', country: 'Inupiak' },
        { value: 'GA', country: 'Irish' },
        { value: 'IT', country: 'Italian' },
        { value: 'JA', country: 'Japanese' },
        { value: 'JW', country: 'Javanese' },
        { value: 'KN', country: 'Kannada' },
        { value: 'KS', country: 'Kashmiri' },
        { value: 'KK', country: 'Kazakh' },
        { value: 'RW', country: 'Kinyarwanda' },
        { value: 'KY', country: 'Kirghiz' },
        { value: 'RN', country: 'Kirundi' },
        { value: 'KO', country: 'Korean' },
        { value: 'KU', country: 'Kurdish' },
        { value: 'LO', country: 'Laothian' },
        { value: 'LA', country: 'Latin' },
        { value: 'LV', country: 'Latvian, Lettish' },
        { value: 'LN', country: 'Lingala' },
        { value: 'LT', country: 'Lithuanian' },
        { value: 'MK', country: 'Macedonian' },
        { value: 'MG', country: 'Malagasy' },
        { value: 'MS', country: 'Malay' },
        { value: 'ML', country: 'Malayalam' },
        { value: 'MT', country: 'Maltese' },
        { value: 'MI', country: 'Maori' },
        { value: 'MR', country: 'Marathi' },
        { value: 'MO', country: 'Moldavian' },
        { value: 'MN', country: 'Mongolian' },
        { value: 'NA', country: 'Nauru' },
        { value: 'NE', country: 'Nepali' },
        { value: 'NO', country: 'Norwegian' },
        { value: 'OC', country: 'Occitan' },
        { value: 'OR', country: 'Oriya' },
        { value: 'OM', country: 'Oromo, Afan' },
        { value: 'PS', country: 'Pashto, Pushto' },
        { value: 'FA', country: 'Persian' },
        { value: 'PL', country: 'Polish' },
        { value: 'PT', country: 'Portuguese' },
        { value: 'PA', country: 'Punjabi' },
        { value: 'QU', country: 'Quechua' },
        { value: 'RM', country: 'Rhaeto-Romance' },
        { value: 'RO', country: 'Romanian' },
        { value: 'RU', country: 'Russian' },
        { value: 'SM', country: 'Samoan' },
        { value: 'SG', country: 'Sangro' },
        { value: 'SA', country: 'Sanskrit' },
        { value: 'SR', country: 'Serbian' },
        { value: 'SH', country: 'Serbo-Croatian' },
        { value: 'ST', country: 'Sesotho' },
        { value: 'TN', country: 'Setswana' },
        { value: 'SN', country: 'Shona' },
        { value: 'SD', country: 'Sindhi' },
        { value: 'SI', country: 'Singhalese' },
        { value: 'SS', country: 'Siswati' },
        { value: 'SK', country: 'Slovak' },
        { value: 'SL', country: 'Slovenian' },
        { value: 'SO', country: 'Somali' },
        { value: 'ES', country: 'Spanish' },
        { value: 'SU', country: 'Sudanese' },
        { value: 'SW', country: 'Swahili' },
        { value: 'SV', country: 'Swedish' },
        { value: 'TL', country: 'Tagalog' },
        { value: 'TG', country: 'Tajik' },
        { value: 'TA', country: 'Tamil' },
        { value: 'TT', country: 'Tatar' },
        { value: 'TE', country: 'Telugu' },
        { value: 'TH', country: 'Thai' },
        { value: 'BO', country: 'Tibetan' },
        { value: 'TI', country: 'Tigrinya' },
        { value: 'TO', country: 'Tonga' },
        { value: 'TS', country: 'Tsonga' },
        { value: 'TR', country: 'Turkish' },
        { value: 'TK', country: 'Turkmen' },
        { value: 'TW', country: 'Twi' },
        { value: 'UK', country: 'Ukranian' },
        { value: 'UR', country: 'Urdu' },
        { value: 'UZ', country: 'Uzbek' },
        { value: 'VI', country: 'Vietnamese' },
        { value: 'VO', country: 'Volapuk' },
        { value: 'CY', country: 'Welsh' },
        { value: 'WO', country: 'Wolof' },
        { value: 'XH', country: 'Xhosa' },
        { value: 'JI', country: 'Yiddish' },
        { value: 'YO', country: 'Yoruba' },
        { value: 'ZU', country: 'Zulu' }
      ],
      themes: [],
      sections: [],
      categories: [],
      selectedThemesAndSections: [],
      selectedProductsAndCategories: [],
      selectedDecosAndSections: [],
      products: [],
      decos: [],
      valid: false,
      searchLanguage: '',
      uploading: false,
      changeTrad: false,
      translation: '{}',
      saveTranslation: {},
      changeConfig: false,
      configuration: '{}',
      saveConfiguration: {}
    }
  },
  watch: {
    currentLanguagesComputed: function () {
      this.searchLanguage = ''
    }
  },
  computed: {
    socleComputed () {
      return this.showPrefixSocle ? this.getFileName(this.currentSite.baseTexture) : ''
    },
    logoComputed () {
      return this.showPrefixLogo ? this.getFileName(this.currentSite.logo) : ''
    },
    logoMobileComputed () {
      return this.showPrefixLogoMobile ? this.getFileName(this.currentSite.logoMobile) : ''
    },
    translationComputed () {
      return this.showPrefixTranslation ? this.getFileName(this.currentSite.translationUrl) : ''
    },
    currentLanguagesComputed () {
      return this.currentSite.languageList
    },
    languagesComputed () {
      return this.languages.map((langue) => {
        return langue.country
      })
    },
    allThemes () {
      return this.wantedThemes.length === this.themes.length
    },
    iconThemes () {
      if (this.allThemes) return 'mdi-checkbox-marked'
      return 'mdi-checkbox-blank-outline'
    },
    allSections () {
      return this.wantedSections.length === this.sections.length
    },
    iconSections () {
      if (this.allSections) return 'mdi-checkbox-marked'
      return 'mdi-checkbox-blank-outline'
    },
    allProducts () {
      return this.wantedProducts.length === this.products.length
    },
    iconProducts () {
      if (this.allProducts) return 'mdi-checkbox-marked'
      return 'mdi-checkbox-blank-outline'
    },
    allDecos () {
      return this.wantedDecos.length === this.decos.length
    },
    iconDecos () {
      if (this.allDecos) return 'mdi-checkbox-marked'
      return 'mdi-checkbox-blank-outline'
    },
    themesCategorized () {
      var themesCategorized = [{ id: 1, name: 'Thèmes', children: [] }]
      this.sections.forEach((section) => {
        var themesArr = []
        this.themes.forEach((theme) => {
          if (theme.sectionList.find((sectionFind) => {
            return sectionFind === section.id
          })) {
            themesArr.push({ id: 'theme' + theme.id + 'section' + section.id, name: theme.name })
          }
        })
        themesCategorized[0].children.push({ id: 'section' + section.id, name: section.name, children: themesArr })
      })
      return themesCategorized
    },
    decosCategorized () {
      var decosCategorized = [{ id: 1, name: 'Motifs', children: [] }]
      this.sections.forEach((section) => {
        var decosArr = []
        this.decos.forEach((deco) => {
          if (deco.sectionList.find((sectionFind) => {
            return sectionFind === section.id
          })) {
            decosArr.push({ id: 'deco' + deco.id + 'section' + section.id, name: deco.name })
          }
        })
        decosCategorized[0].children.push({ id: 'section' + section.id, name: section.name, children: decosArr })
      })
      return decosCategorized
    },
    productsCategorized () {
      var productsCategorized = [{ id: 1, name: 'Produits', children: [] }]
      this.categories.forEach((category) => {
        var productsArr = []
        this.products.forEach((product) => {
          if (product.categoryId === category.id) {
            productsArr.push({ id: 'product' + product.id + 'category' + category.id, name: product.name })
          }
        })
        productsCategorized[0].children.push({ id: 'category' + category.id, name: category.name, children: productsArr })
      })
      return productsCategorized
    }
  },
  created () {
    this.getCurrentSite()
  },
  mounted () {
    this.addLogo()
    this.addLogoMobile()
  },
  methods: {
    addSocle () {
      if (this.inputFileSocle && this.inputFileSocle.name) {
        var reader = new FileReader()
        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.inputFileSocle.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentSite.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSiteSocle', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.inputFileSocle)
      }
    },
    addLogo () {
      if (this.inputFileTranslation && this.inputFileLogo.name) {
        const reader = new FileReader()
        reader.onload = () => {
          const data = new FormData()
          const blob = new Blob([reader.result])
          data.append('fileName', this.inputFileLogo.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentSite.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSiteLogo', data)
        }
        reader.readAsArrayBuffer(this.inputFileLogo)
      }
    },
    addLogoMobile () {
      if (this.inputFileTranslation && this.inputFileLogoMobile.name) {
        const reader = new FileReader()
        reader.onload = () => {
          const data = new FormData()
          const blob = new Blob([reader.result])
          data.append('fileName', this.inputFileLogoMobile.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentSite.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSiteLogoMobile', data)
        }
        reader.readAsArrayBuffer(this.inputFileLogoMobile)
      }
    },
    addTranslation () {
      if (this.inputFileTranslation && this.inputFileTranslation.name) {
        var reader = new FileReader()

        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.inputFileTranslation.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentSite.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSiteTranslation', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.inputFileTranslation)
      }
    },
    getTranslation () {
      this.$store.dispatch('download', this.currentSite.translationUrl).then((res) => {
        this.translation = JSON.stringify(res, undefined, 2)
        this.saveTranslation = res
      })
    },
    changeTranslation () {
      try {
        if (this.currentSite.id === '') { // new site
          JSON.parse(this.translation, undefined, 2)
        } else {
          // JSON.parse is important to test if the JSON is valid
          if (JSON.stringify(this.saveTranslation) !== JSON.stringify(JSON.parse(this.translation, undefined, 2))) {
            this.saveTranslation = JSON.parse(this.translation)
            var data = new FormData()
            var blob = new Blob([this.translation], { type: 'text/plain;charset=utf-8', encoding: 'UTF-8' })
            data.append('fileName', 'traduction.json')
            data.append('id', this.currentSite.id)
            data.append('file', blob)
            this.$store.dispatch('uploadSiteTranslation', data)
            this.$emit('notify', {
              color: 'green',
              text: this.$t('SitesForm.SavedChanges')
            })
          }
        }
        this.changeTrad = false
      } catch (e) {
        console.log(e)
        this.$emit('notify', {
          color: 'red',
          text: this.$t('SitesForm.ErrorJSON')
        })
      }
    },
    getConfiguration () {
      this.$store.dispatch('download', this.currentSite.configurationUrl).then((res) => {
        this.configuration = JSON.stringify(res, undefined, 2)
        this.saveConfiguration = res
      })
    },
    changeConfiguration () {
      try {
        if (this.currentSite.id === '') {
          JSON.parse(this.translation, undefined, 2)
        } else if (JSON.stringify(this.saveConfiguration) !== JSON.stringify(JSON.parse(this.configuration, undefined, 2))) {
          this.saveConfiguration = JSON.parse(this.configuration)
          var data = new FormData()
          var blob = new Blob([this.configuration], { type: 'text/plain;charset=utf-8', encoding: 'UTF-8' })
          data.append('fileName', 'configuration.json')
          data.append('id', this.currentSite.id)
          data.append('file', blob)
          this.$store.dispatch('uploadSiteConfiguration', data)
          this.$emit('notify', {
            color: 'green',
            text: this.$t('SitesForm.SavedChanges')
          })
        }
        this.changeConfig = false
      } catch (e) {
        console.log(e)
        this.$emit('notify', {
          color: 'red',
          text: this.$t('SitesForm.ErrorJSON')
        })
      }
    },
    getFileName (name) {
      if (!name) return ''
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    toggleThemes () {
      if (this.allThemes) {
        this.wantedThemes = []
      } else {
        this.wantedThemes = this.themes
      }
    },
    toggleSections () {
      if (this.allSections) {
        this.wantedSections = []
      } else {
        this.wantedSections = this.sections
      }
    },
    toggleProducts () {
      if (this.allProducts) {
        this.wantedProducts = []
      } else {
        this.wantedProducts = this.products
      }
    },
    toggleDecos () {
      if (this.allDecos) {
        this.wantedDecos = []
      } else {
        this.wantedDecos = this.decos
      }
    },
    async getData () {
      await this.getCategories()
      await this.getProducts()
      this.categories.forEach((category) => {
        this.products.forEach((product) => {
          if (!this.currentSite.productExceptionList.find((productId) => {
            return productId === product.id
          })) {
            if (product.categoryId === category.id) {
              this.selectedProductsAndCategories.push('product' + product.id + 'category' + category.id)
            }
          }
        })
      })
      await this.getSections()
      await this.getDecos()
      this.sections.forEach((section) => {
        this.decos.forEach((deco) => {
          if (!this.currentSite.decoExceptionList.find((decoId) => {
            return decoId === deco.id
          }) && deco.sectionList.find((sectionId) => {
            return sectionId === section.id
          })) {
            this.selectedDecosAndSections.push('deco' + deco.id + 'section' + section.id)
          }
        })
      })
      await this.getThemes()
      this.sections.forEach((section) => {
        this.themes.forEach((theme) => {
          if (!this.currentSite.themeExceptionList.find((themeId) => {
            return themeId === theme.id
          }) && theme.sectionList.find((sectionId) => {
            return sectionId === section.id
          })) {
            this.selectedThemesAndSections.push('theme' + theme.id + 'section' + section.id)
          }
        })
      })
      this.getTranslation()
      this.getConfiguration()
      this.loading = false
    },
    getCurrentSite () {
      if (this.$route.params.id.indexOf('new') === -1) {
        this.$store.dispatch('getSite', this.$route.params.id).then(() => {
          this.currentSite = this.$store.getters['GET_CURRENT_SITE']()
          if (this.currentSite === null) {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('SitesForm.SiteNotFound')
            })
            this.$router.push({ name: 'Sites' })
          }
          this.currentSite.languageList = this.currentSite.languageList.map((lang) => {
            return lang.country
          })
          if (!this.currentSite.features) {
            this.currentSite.features = [
              { name: 'Products', value: true },
              { name: 'Themes', value: true },
              { name: 'Patterns', value: true },
              { name: 'Texts', value: true },
              { name: 'Image', value: true },
              { name: 'Measures', value: true },
              { name: 'Layers', value: true },
              { name: 'Import', value: true },
              { name: 'Unsplash', value: true }
            ]
          }
          this.getData()
        })
      } else {
        this.getData()
      }
    },
    async getSections () {
      return this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
      })
    },
    async getProducts () {
      return this.$store.dispatch('getAllProducts').then(() => {
        this.products = this.$store.getters['GET_PRODUCTS']()
      })
    },
    async getDecos () {
      return this.$store.dispatch('getAllDecos').then(() => {
        this.decos = this.$store.getters['GET_DECOS']()
      })
    },
    async getThemes () {
      return this.$store.dispatch('getAllThemes').then(() => {
        this.themes = this.$store.getters['GET_THEMES']()
      })
    },
    async getCategories () {
      return this.$store.dispatch('getAllCategories').then(() => {
        this.categories = this.$store.getters['GET_CATEGORIES']()
      })
    },
    addOrModifySite () {
      this.$refs.form.validate()
      this.uploading = true
      if (this.valid) {
        var tempLangList = []
        this.currentSite.languageList.forEach((lang) => {
          var langOrigin = this.languages.find((l) => {
            return l.country === lang
          })
          if (langOrigin) {
            tempLangList.push(langOrigin)
          }
        })
        this.currentSite.languageList = tempLangList
        this.currentSite.productExceptionList = this.products.filter((product) => {
          return !this.selectedProductsAndCategories.find((wantedP) => {
            return wantedP.includes(product.id)
          })
        }).map((p) => {
          return p.id
        })
        this.currentSite.sectionExceptionList = this.sections.filter((section) => {
          return !this.wantedSections.find((wantedS) => {
            return wantedS.id === section.id
          })
        }).map((s) => {
          return s.id
        })
        this.currentSite.decoExceptionList = this.decos.filter((deco) => {
          return !this.selectedDecosAndSections.find((wantedD) => {
            return wantedD.includes(deco.id)
          })
        }).map((d) => {
          return d.id
        })
        this.currentSite.themeExceptionList = this.themes.filter((theme) => {
          return !this.selectedThemesAndSections.find((wantedT) => {
            return wantedT.includes(theme.id)
          })
        }).map((t) => {
          return t.id
        })

        if (this.currentSite.id) {
          this.modifySite()
        } else {
          this.addSite()
        }
      }
    },
    addSite () {
      this.$store.dispatch('addSite', this.currentSite).then(() => {
        this.currentSite = this.$store.getters['GET_CURRENT_SITE']()
        this.addLogo()
        this.addLogoMobile()
        this.addSocle()
        this.changeTranslation()
        this.changeConfiguration()
        setTimeout(() => {
          this.$router.push({ name: 'Sites' })
        }, 1000)
      }).catch((err) => {
        this.uploading = false
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    modifySite () {
      this.$store.dispatch('editSite', this.currentSite).then(() => {
        this.addLogo()
        this.addLogoMobile()
        this.addSocle()
        setTimeout(() => {
          this.$router.push({ name: 'Sites' })
        }, 1000)
      }).catch((err) => {
        this.uploading = false
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>
.switchInCol {
  justify-content: center;
  display: flex;
}
</style>

<template>
  <div class="pa-5">
    <v-card class="mx-auto">
      <v-card-title>
        <h2 v-t="'Products.ProductManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$router.push('Products/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Products.AddAProduct') }}
        </v-btn>
      </v-card-title>
      <div class="table-container">
        <div class="header-row">
          <div class="little-space-teehee"></div>
          <div class="table-element drag"></div>
          <div v-t="'General.PublishingStatus'" class="table-element"></div>
          <div v-t="'Products.ProductName'" class="table-element"></div>
          <div class="table-element">
            {{ $t('General.SKU') }}
            <v-icon @click="sortProducts('sku', 'asc')">mdi-arrow-up</v-icon>
            <v-icon @click="sortProducts('sku', 'desc')">mdi-arrow-down</v-icon>
          </div>
          <div v-t="'Products.Categorie'" class="table-element"></div>
          <div v-t="'General.Actions'" class="table-element"></div>
        </div>
        <vuetify-draggable-treeview v-model="products" @input="updateIndexes" style="display: flex; flex-flow: column nowrap; justify-content:space-evenly;">
          <template v-slot:label="{ item }">
            <div class="body-row">
              <div class="table-element drag">
                <div class="table-element-center">
                  <v-icon style="padding: 10px; cursor: move">mdi-cursor-move</v-icon>
                </div>
              </div>
              <div class="table-element">
                <div class="table-element-center">
                <v-switch @change="modifyProduct(item)"
                v-model="item.published"
                ></v-switch>
                </div>
              </div>
              <div class="table-element">
                {{ item.name }}
              </div>
              <div class="table-element">{{ item.sku }}</div>
              <div class="table-element">{{ getCategoryLabel(item.categoryId) }}</div>
              <div class="table-element">
                <template>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="black" icon @click="configProduct(item)">
                          <v-icon>mdi-cube-outline</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.3DConfiguration'">Configuration 3D</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="secondary" icon dark @click="$router.push('Products/' + item.id)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.Edit'"></span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="black" icon @click="copyProduct(item)">
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.Duplicate'"></span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="red" icon @click="deleteProduct(item)" >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.Delete'"></span>
                    </v-tooltip>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </vuetify-draggable-treeview>
      </div>
    </v-card>
  </div>
</template>

<script>
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'

export default {
  name: 'Products',
  components: { VuetifyDraggableTreeview },
  data () {
    return {
      products: [],
      categories: [],
      sortBy: 'asc'
    }
  },
  created () {
    this.getProducts()
    this.getCategories()
  },
  methods: {
    initProducts () {
      this.products.sort((a, b) => {
        return b.index < a.index ? 1 : -1
      })
    },
    async updateIndexes (products) {
      return new Promise((resolve, reject) => {
        products.forEach(async (product) => {
          if (product.index !== products.indexOf(product)) {
            product.index = products.indexOf(product)
            await this.updateProduct(product)
          }
          if (product.index === products.length - 1) {
            resolve()
          }
        })
      })
    },
    sortProducts (field, direction) {
      this.products.sort((a, b) => {
        if (a[field] < b[field]) {
          return direction === 'asc' ? -1 : 1
        }
        if (a[field] > b[field]) {
          return direction === 'asc' ? 1 : -1
        }
        return 0
      })
      this.products.forEach((p, index) => {
        if (p.index !== index) {
          p.index = index
          this.updateProduct(p)
        } else {
          p.index = index
        }
      })
    },
    async updateProduct (product) {
      await this.$store.dispatch('editProduct', product).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    configProduct (product) {
      this.$router.push('/ThreeScene/' + product.id)
    },
    copyProduct (product) {
      var newProduct = JSON.parse(JSON.stringify(product))
      delete newProduct.id
      newProduct.name = newProduct.name + ' (Copie)'
      this.$store.dispatch('addProduct', newProduct).then(() => {
        this.getProducts()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    deleteProduct (product) {
      this.$store.dispatch('deleteProduct', product.id).then(async () => {
        const index = this.products.indexOf(product)
        this.products.splice(index, 1)
        await this.updateIndexes(this.products)
        this.getProducts()
        this.$emit('notify', {
          color: 'green',
          text: this.$t('Messages.ProductDeleted')
        })
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    async getProducts () {
      await this.$store.dispatch('getAllProducts').then(() => {
        this.products = this.$store.getters['GET_PRODUCTS']()
        this.initProducts()
      })
    },
    getCategories () {
      this.$store.dispatch('getAllCategories').then(() => {
        this.categories = this.$store.getters['GET_CATEGORIES']()
      })
    },
    getCategoryLabel (id) {
      var categorie = this.categories.find((category) => {
        return category.id === id
      })
      if (categorie) {
        return categorie.name
      } else {
        return ''
      }
    },
    modifyProduct (product) {
      this.$store.dispatch('editProduct', product).then(() => {
        this.getProducts()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>
.table-container {
  display: flex;
  flex-flow: column nowrap;
}

.header-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.header-row:hover, .body-row:hover {
  border-bottom: solid 1px rgba(0, 0, 0, 0.24);
}

.header-row .table-element {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
}

.body-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.little-space-teehee {
  width: 24px;
}

.table-element {
  flex: 1;
  flex-basis: 0;
  min-width: 0;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.87);
  white-space: normal;
}

.drag {
  flex-grow: 0.2;
}

.table-element-center {
  display: flex;
  justify-content: center;
}
</style>

import axios from 'axios'

const instance = axios.create({
  withCredentials: true
})

export async function getAllTemplates ({ commit }) {
    await instance.get('/templates')
        .then((res) => {
            commit('SET_ALL_TEMPLATES', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getTemplate ({ commit }, data) {
    await instance.get('/templates/' + data)
        .then((res) => {
            commit('SET_SELECTED_TEMPLATE', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function addTemplate ({ commit }, data) {
    return await instance.post('/templates/', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
      return res.data
    })
}

export async function modifyTemplate ({ commit }, data) {
    return await instance.put('/templates/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        return res.data
    })
}

export async function deleteTemplate ({ commit }, data) {
    await instance.delete('/templates/' + data).then((res) => {
        return res.data
    })
}

export async function getAllSites ({ commit }) {
  await instance.get('/sites')
    .then((res) => {
      commit('SET_SITES', res.data)
    })
    .catch((err) => {
      console.log(err.response)
    })
}

export async function getSite ({ commit }, data) {
    await instance.get('/sites/' + data)
        .then((res) => {
            commit('SET_CURRENT_SITE', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editSite ({ commit }, data) {
  await instance.put('/sites/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
      commit('SET_CURRENT_SITE', res.data)
    return res.data
  })
}

export async function addSite ({ commit }, data) {
  await instance.post('/sites', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
      commit('SET_CURRENT_SITE', res.data)
      return res.data
  })
}

export async function deleteSite ({ commit }, data) {
  await instance.delete('/sites/' + data).then((res) => {
    commit('SET_SITES', res.data)
  })
}

export async function getAllSections ({ commit }) {
  await instance.get('/sections')
    .then((res) => {
      commit('SET_SECTIONS', res.data)
    })
    .catch((err) => {
      console.log(err.response)
    })
}

export async function getSection ({ commit }, data) {
  await instance.get('/sections/' + data)
    .then((res) => {
      commit('SET_CURRENT_SECTION', res.data)
    })
    .catch((err) => {
      console.log(err.response)
    })
}

export async function editSection ({ commit }, data) {
  await instance.put('/sections/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
      commit('SET_CURRENT_SECTION', res.data)
      return res.data
  })
}

export async function editPublishedSection ({ commit }, data) {
    await instance.put('/sections/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_SECTION', res.data)
        return res.data
    })
}

export async function addSection ({ commit }, data) {
  await instance.post('/sections', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
      commit('SET_CURRENT_SECTION', res.data)
      return res.data
  })
}

export async function deleteSection ({ commit }, data) {
  await instance.delete('/sections/' + data).then((res) => {
    commit('SET_SECTIONS', res.data)
  })
}

export async function getAllCategories ({ commit }) {
    await instance.get('/categories')
        .then((res) => {
            commit('SET_CATEGORIES', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getCategory ({ commit }, data) {
    await instance.get('/categories/' + data)
        .then((res) => {
            commit('SET_CURRENT_CATEGORY', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editCategory ({ commit }, data) {
    await instance.put('/categories/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_CATEGORY', res.data)
        return res.data
    })
}

export async function addCategory ({ commit }, data) {
    await instance.post('/categories', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_CATEGORY', res.data)
        return res.data
    })
}

export async function deleteCategory ({ commit }, data) {
    await instance.delete('/categories/' + data).then((res) => {
        commit('SET_CATEGORIES', res.data)
    })
}

export async function getAllProducts ({ commit }) {
    await instance.get('/products')
        .then((res) => {
            commit('SET_PRODUCTS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getProduct ({ commit }, data) {
    await instance.get('/products/' + data)
        .then((res) => {
            commit('SET_CURRENT_PRODUCT', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editProduct ({ commit }, data) {
    return await instance.put('/products/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_PRODUCT', res.data)
      return res.data
    })
}

export async function addProduct ({ commit }, data) {
    return await instance.post('/products', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_PRODUCT', res.data)
      return res.data
    })
}

export async function deleteProduct ({ commit }, data) {
    await instance.delete('/products/' + data).then((res) => {
        commit('SET_PRODUCTS', res.data)
    })
}

export async function uploadProductObject3D ({ commit }, data) {
  await instance.post('/upload/product/object3D', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadProductPreview ({ commit }, data) {
  await instance.post('/upload/product/preview', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadProductPatron ({ commit }, data) {
  await instance.post('/upload/product/patron', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadProductTechnicalDetails ({ commit }, data) {
  await instance.post('/upload/product/technicalDetails', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadDecoSvg ({ commit }, data) {
  await instance.post('/upload/deco/svg', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadThemeSvg ({ commit }, data) {
  await instance.post('/upload/theme/svg', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadThemePreview ({ commit }, data) {
  await instance.post('/upload/theme/preview', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadMeasureSvg ({ commit }, data) {
  await instance.post('/upload/measure/svg', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadTextSvg ({ commit }, data) {
  await instance.post('/upload/text/svg', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadSiteSocle ({ commit }, data) {
  await instance.post('/upload/site/socle', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadSiteLogo ({ commit }, data) {
    await instance.post('/upload/site/logo', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
        return res.data
    })
}

export async function uploadSiteLogoMobile ({ commit }, data) {
    await instance.post('/upload/site/logoMobile', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
        return res.data
    })
}

export async function uploadSiteTranslation ({ commit }, data) {
  await instance.post('/upload/site/translation', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadSiteConfiguration ({ commit }, data) {
  await instance.post('/upload/site/configuration', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadSectionPreview ({ commit }, data) {
  await instance.post('/upload/section/preview', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function uploadMaterialEnvMap ({ commit }, data) {
  await instance.post('/upload/material/envMap', data, { headers: { 'content-type': 'multipart/form-data' } }).then((res) => {
      return res.data
  })
}

export async function download ({ commit }, data) {
  return await instance.get('/download?filename=' + data).then(async (res) => {
    return await axios(res.data.file, {
      url: res.data.file,
      methods: 'get',
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then((response) => {
      return response.data
    })
  })
  .catch(e => {
    console.log('Error fetching file "' + data + '" -> ' + e)
  })
}

export async function getAllTags ({ commit }) {
    await instance.get('/tags')
        .then((res) => {
            commit('SET_TAGS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getTag ({ commit }, data) {
    await instance.get('/tags/' + data)
        .then((res) => {
            commit('SET_CURRENT_TAG', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editTag ({ commit }, data) {
    await instance.put('/tags/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_TAG', res.data)
        return res.data
    })
}

export async function addTag ({ commit }, data) {
    await instance.post('/tags', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_TAG', res.data)
        return res.data
    })
}

export async function deleteTag ({ commit }, data) {
    await instance.delete('/tags/' + data).then((res) => {
        commit('SET_TAGS', res.data)
    })
}

export async function getAllDecos ({ commit }) {
    await instance.get('/decos')
        .then((res) => {
            commit('SET_DECOS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getDecosPage ({ commit }, data) {
    await instance.get('/decos/' + data)
        .then((res) => {
            commit('SET_CURRENT_DECOS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getDeco ({ commit }, data) {
    await instance.get('/decos/' + data)
        .then((res) => {
            commit('SET_CURRENT_DECO', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editDeco ({ commit }, data) {
    await instance.put('/decos/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_DECO', res.data)
        return res.data
    })
}

export async function addDeco ({ commit }, data) {
    await instance.post('/decos', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_DECO', res.data)
        return res.data
    })
}

export async function deleteDeco ({ commit }, data) {
    await instance.delete('/decos/' + data).then((res) => {
        commit('SET_DECOS', res.data)
    })
}

export async function getAllThemes ({ commit }) {
    await instance.get('/themes')
        .then((res) => {
            commit('SET_THEMES', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getThemesPage ({ commit }, data) {
    await instance.get('/themes/page/' + data)
        .then((res) => {
            commit('SET_CURRENT_THEMES', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getTheme ({ commit }, data) {
    await instance.get('/themes/' + data)
        .then((res) => {
            commit('SET_CURRENT_THEME', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editTheme ({ commit }, data) {
    await instance.put('/themes/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_THEME', res.data)
        return res.data
    })
}

export async function addTheme ({ commit }, data) {
    await instance.post('/themes', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_THEME', res.data)
        return res.data
    })
}

export async function deleteTheme ({ commit }, data) {
    await instance.delete('/themes/' + data).then((res) => {
        commit('SET_THEMES', res.data)
    })
}

export async function getAllTexts ({ commit }) {
    await instance.get('/texts')
        .then((res) => {
            commit('SET_TEXTS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getText ({ commit }, data) {
    await instance.get('/texts/' + data)
        .then((res) => {
            commit('SET_CURRENT_TEXT', res.data)
            return res.data
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editText ({ commit }, data) {
    await instance.put('/texts/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_TEXT', res.data)
    })
}

export async function addText ({ commit }, data) {
    await instance.post('/texts', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_TEXT', res.data)
        return res.data
    })
}

export async function deleteText ({ commit }, data) {
    await instance.delete('/texts/' + data).then((res) => {
        commit('SET_TEXTS', res.data)
    })
}

export async function getAllMeasures ({ commit }) {
    await instance.get('/measures')
        .then((res) => {
            commit('SET_MEASURES', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getMeasure ({ commit }, data) {
    await instance.get('/measures/' + data)
        .then((res) => {
            commit('SET_CURRENT_MEASURE', res.data)
            return res.data
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editMeasure ({ commit }, data) {
    await instance.put('/measures/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_MEASURE', res.data)
    })
}

export async function addMeasure ({ commit }, data) {
    await instance.post('/measures', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_MEASURE', res.data)
        return res.data
    })
}

export async function deleteMeasure ({ commit }, data) {
    await instance.delete('/measures/' + data).then((res) => {
        commit('SET_MEASURES', res.data)
    })
}

export async function getAllMaterials ({ commit }) {
    await instance.get('/materials')
        .then((res) => {
            commit('SET_MATERIALS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getMaterial ({ commit }, data) {
    await instance.get('/materials/' + data)
        .then((res) => {
            commit('SET_CURRENT_MATERIAL', res.data)
            return res.data
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editMaterial ({ commit }, data) {
    const datasend = JSON.parse(JSON.stringify(data))
    datasend.materialJson.transmission = data.materialJson.transmission
    await instance.put('/materials/' + data.id, datasend, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_MATERIAL', res.data)
    })
}

export async function addMaterial ({ commit }, data) {
    await instance.post('/materials', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_MATERIAL', res.data)
        return res.data
    })
}

export async function deleteMaterial ({ commit }, data) {
    await instance.delete('/materials/' + data).then((res) => {
        commit('SET_MATERIALS', res.data)
    })
}

export async function getAllAccounts ({ commit }) {
    await instance.get('/users')
        .then((res) => {
            commit('SET_ACCOUNTS', res.data)
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function getAccount ({ commit }, data) {
    await instance.get('/users/' + data)
        .then((res) => {
            commit('SET_CURRENT_ACCOUNT', res.data)
            return res.data
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export async function editAccount ({ commit }, data) {
    await instance.put('/users/' + data.id, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_ACCOUNT', res.data)
    })
}

export async function addAccount ({ commit }, data) {
    await instance.post('/users', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } }).then((res) => {
        commit('SET_CURRENT_ACCOUNT', res.data)
        return res.data
    })
}

export async function deleteAccount ({ commit }, data) {
    await instance.delete('/users/' + data).then((res) => {
        commit('SET_ACCOUNTS', res.data)
    })
}

export async function login ({ commit }, data) {
  await instance.post('/login', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
}
export async function logout () {
  await instance.post('/logout')
}

export async function getAuthenticatedUser ({ commit }, data) {
  await instance.get('/api/me').then((res) => {
    commit('SET_IS_AUTHENTICATED', true)
    commit('SET_ROLES', res.data.roles)
    commit('SET_USERNAME', res.data.username)
  }).catch(() => {
    commit('SET_IS_AUTHENTICATED', false)
    commit('SET_ROLES', [])
    commit('SET_USERNAME', '')
  })
}

export function storeAllObjects ({ commit }, data) {
    commit('SET_ALL_OBJECTS', data)
}

export function clearSelectedFonts ({ commit }) {
    commit('SET_SELECTED_FONTS', [])
}

export function addSelectedFont ({ commit, state }, data) {
    var selectedFonts = state.selectedFonts

    if (!selectedFonts.find((font) => { return data === font })) {
        selectedFonts.push(data)
        commit('SET_SELECTED_FONTS', selectedFonts)
    }
}

export async function downloadFile ({ commit, state }, data) {
    await instance.get('/downloadFile?filename=' + data, { responseType: 'blob' })
        .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', data)
            document.body.appendChild(fileLink)

            fileLink.click()
        })
        .catch((err) => {
            console.log(err.response)
        })
}

export function GET_SITES (state) {
  return () => {
    return state.sites
  }
}

export function GET_CURRENT_SITE (state) {
  return () => {
    return state.currentSite
  }
}

export function GET_SECTIONS (state) {
  return () => {
    return state.sections
  }
}

export function GET_CURRENT_SECTION (state) {
  return () => {
    return state.currentSection
  }
}

export function GET_CATEGORIES (state) {
  return () => {
    return state.categories
  }
}

export function GET_CURRENT_CATEGORY (state) {
  return () => {
    return state.currentCategory
  }
}

export function GET_PRODUCTS (state) {
  return () => {
    return state.products
  }
}

export function GET_ALL_TEMPLATES (state) {
  return () => {
    return state.templates
  }
}

export function GET_CURRENT_PRODUCT (state) {
  return () => {
    return state.currentProduct
  }
}

export function GET_TAGS (state) {
  return () => {
    return state.tags
  }
}

export function GET_CURRENT_TAG (state) {
  return () => {
    return state.currentTag
  }
}

export function GET_DECOS (state) {
  return () => {
    return state.decos
  }
}

export function GET_CURRENT_DECOS (state) {
  return () => {
    return state.currentDecos
  }
}

export function GET_CURRENT_DECO (state) {
  return () => {
    return state.currentDeco
  }
}

export function GET_THEMES (state) {
  return () => {
    return state.themes
  }
}

export function GET_CURRENT_THEME (state) {
  return () => {
    return state.currentTheme
  }
}

export function GET_CURRENT_THEMES (state) {
  return () => {
    return state.currentThemes
  }
}

export function GET_TEXTS (state) {
  return () => {
    return state.texts
  }
}

export function GET_CURRENT_TEXT (state) {
  return () => {
    return state.currentText
  }
}

export function GET_MEASURES (state) {
  return () => {
    return state.measures
  }
}

export function GET_CURRENT_MEASURE (state) {
  return () => {
    return state.currentMeasure
  }
}

export function GET_MATERIALS (state) {
  return () => {
    return state.materials
  }
}

export function GET_CURRENT_MATERIAL (state) {
  return () => {
    return state.currentMaterial
  }
}

export function GET_ACCOUNTS (state) {
  return () => {
    return state.accounts
  }
}

export function GET_CURRENT_ACCOUNT (state) {
  return () => {
    return state.currentAccount
  }
}

export function GET_ALL_OBJECTS (state) {
  return () => {
    return state.allObjects
  }
}

export function GET_SELECTED_FONTS (state) {
  return () => {
    return state.selectedFonts
  }
}

export function GET_FONTS_READY_STATE (state) {
  return () => {
    return state.areFontsLoaded
  }
}

export function GET_IS_AUTHENTICATED (state) {
  return () => {
    return state.isAuthenticated
  }
}

export function GET_ROLES (state) {
  return () => {
    return state.roles
  }
}

export function GET_USERNAME (state) {
  return () => {
    return state.username
  }
}

export function GET_LAST_SELECTED_THEME (state) {
  return () => {
    return state.lastSelectedTheme
  }
}
